@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@300;400;500;700&family=Montserrat:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", "Arima Madurai", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: #1d1d1d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
